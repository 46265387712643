<template>
    <div>
        <h2>页面不存在</h2>
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style scoped>

</style>